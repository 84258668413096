import React from "react";
import PropTypes from 'prop-types';
import { Link } from "gatsby";
import scrollTo from 'gatsby-plugin-smoothscroll';
import { Button } from "src/components/common";
import getStaticContent from "../../../../utils/getStaticContent";

const Header = ({ data }) => {
  const createTitleDescription = () => {
    return { __html: `${data.titleDescription}` }
  }

  const createSubtitleDescription = () => {
    return { __html: `${data.subtitleHeader}` }
  }
  

  return (
    <div className="-mt-12 lg:-mt-20 relative flex items-center justify-evenly h-screen min-h-header-sm lg:min-h-header-lg">
      <div className="container relative z-10">
        <div className="text-center mb-8 mt-2 sm:mt-0 text-5xl lg:text-7xl 2xl:text-8xl">
          <div dangerouslySetInnerHTML={createTitleDescription()} />
          <div className="text-xl lg:text-2xl" dangerouslySetInnerHTML={createSubtitleDescription()} />
        </div>
        <div className="mouse_scroll hidden lg:block" onClick={() => scrollTo(`#products`)} role="button">
          <div className="mouse">
            <div className="wheel"></div>
          </div>
          <div>
            <span className="m_scroll_arrows unu"></span>
            <span className="m_scroll_arrows doi"></span>
            <span className="m_scroll_arrows trei"></span>
          </div>
        </div>
      </div>
      <div className="absolute z-21 w-auto min-w-full min-h-full max-w-none bg-timeline bg-bottom bg-no-repeat">
      </div>
    </div>
  )
}

export default Header;

Header.propTypes = {
  data: PropTypes.object.isRequired,
};
