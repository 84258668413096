import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

import Layout from "src/components/layout/Layout";
import Header from "src/components/home/Header";
import Products from "src/components/home/Products";
import Services from "src/components/home/Services";
import Capabilities from "src/components/home/Capabilities";
import Clients from "src/components/home/Clients";
import Contact from "src/components/common/Contact";
import getCurrentLanguage from "../../utils/getCurrentLanguage";
import Divider from "../components/common/Divider";

const Home = ({ data }) => {
  
  const filterByLocale = (data) => {
    const filteredData = data.edges.filter(field => field.node.locale === getCurrentLanguage())
    return filteredData.length === 1 ? filteredData[0].node : filteredData;
  }

  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Invera</title>
      </Helmet>
      {getCurrentLanguage() &&
      <Layout>
        <>
          <Header data={filterByLocale(data.allDatoCmsHomeHeader)} />
          <div id="products">
            <Products
              dataDescription={filterByLocale(data.allDatoCmsProductsDescription)}
              dataProducts={filterByLocale(data.allDatoCmsProduct)}
            />          
          </div>
          <div id="capabilities">
            <Capabilities
              dataDescription={filterByLocale(data.allDatoCmsCapabilitiesDescription)}
              dataCapabilities={filterByLocale(data.allDatoCmsCapability)}
            />
          </div>
          <div id="contact">
            <Contact bg="dark" />
          </div> 
        </>
      </Layout>}
    </>
  )
}


export const query = graphql`
  {
    allDatoCmsHomeHeader {
      edges {
        node {
          locale
          titleDescription
          subtitleHeader
        }
      }
    }
    allDatoCmsProductsDescription {
      edges {
        node {
          locale
          description
        }
      }
    }
    allDatoCmsProduct(sort: { fields: order }) {
      edges {
        node {
          id
          locale
          name
          icon
          iconActive
          iconMargin
          title
          image {
            url
            alt
            title
          }
          description
          logos {
            alt
            title
            url
          }
        }
      }
    }
    allDatoCmsCapabilitiesDescription {
      edges {
        node {
          locale
          description
        }
      }
    }
    allDatoCmsCapability(sort: { fields: order }) {
      edges {
        node {
          id
          locale
          title
          shortDescription
          longDescription
          image {
            url
            alt
          }
        }
      }
    }
    allDatoCmsClientsDescription {
      edges {
        node {
          locale
          description
        }
      }
    }
    allDatoCmsClient {
      edges {
        node {
          id
          locale
          name
          logo {
            url
          }
        }
      }
    }
  }
`

export default Home
