import React, {useState, useEffect} from "react";
import PropTypes from 'prop-types';

import Selector from "src/components/home/Products/Slider/Selector";
import { Progress } from "src/components/common";

const Slider = ({ active, setActive, products, autoplay, interval }) => {

  const [arrowPosition, serArrowPosition] = useState();
  const [arrowHorizontalPosition, serArrowHorizontalPosition] = useState();

  const createIcon = (id, icon, iconActive) => {
    return { __html: `${active === id ? iconActive : icon}` }
  }

  const createDescription = (description) => {
    return { __html: `${description}` }
  }

  const productsLength = products.length;
  let activeProduct = products.find(product => product.node.id === active)?.node;
  let activeProductIndex = products.findIndex(product => product.node.id === active) + 1;
  
  if(!activeProduct) {
    setActive(products[0].node.id)
    activeProduct = products[0];
    activeProductIndex = products[0]
  }

  useEffect(() => {

    if (activeProductIndex == 1) {
      serArrowPosition({
        transform: `translate(${0}%, ${((100 / productsLength) * activeProductIndex) - (100 / productsLength) / 2}%)`,
        marginTop: "-36px"
      })
      serArrowHorizontalPosition({
        transform: `translate(${((100 / productsLength) * activeProductIndex) - (100 / productsLength) / 2}%, ${0})`,
        marginLeft: "-16px"
      })
    } else if (activeProductIndex == productsLength) {
      serArrowPosition({
        transform: `translate(${0}%, ${((100 / productsLength) * activeProductIndex) - (100 / productsLength) / 2}%)`,
        marginTop: "12px"
      })
      serArrowHorizontalPosition({
        transform: `translate(${((100 / productsLength) * activeProductIndex) - (100 / productsLength) / 2}%, ${0})`,
        marginLeft: "-4px"
      })
    } else {
      serArrowPosition({
        transform: `translate(${0}%, ${((100 / productsLength) * activeProductIndex) - (100 / productsLength) / 2}%)`,
        marginTop: "-12px"
      })
      serArrowHorizontalPosition({
        transform: `translate(${((100 / productsLength) * activeProductIndex) - (100 / productsLength) / 2}%, ${0})`,
        marginLeft: "-8px"
      })
    }

  }, [active])

  return (
    <div className="flex flex-col mt-16 xl:flex-row xl:space-y-0">
      <div className={`grid grid-cols-${productsLength} gap-2 xs:gap-4 xl:flex xl:flex-col`}>
        {products.map(product => (
          <Selector key={product.node.id} id={product.node.id} active={active} setActive={setActive}>
            <div
              className={`inline-block ${product.node.iconMargin && product.node.iconMargin}`}
              dangerouslySetInnerHTML={createIcon(product.node.id, product.node.icon, product.node.iconActive)}
            >
            </div>
            <p className="-mt-2 text-sm sm:text-lg leading-5 lg:whitespace-nowrap">{product.node.name}</p>
          </Selector>      
        ))}
      </div>
      <div className="flex py-2.5 lg:px-2.5 items-center">
        {/* <div className={`
          flex
          justify-center
          items-center
          w-full
          lg:h-full
          transition
          duration-500
          ease-in-out
          transform
          `} */}
        <div
          className={`
          hidden
          xl:flex
          w-full
          h-full
          transition
          duration-500
          ease-in-out
          transform
          `}
          style={arrowPosition}
        >
          <svg className="transform lg:-rotate-90" width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className="fill-primary" d="M14.5981 19.5C13.4434 21.5 10.5566 21.5 9.40192 19.5L0.74167 4.5C-0.413031 2.5 1.03035 -4.50414e-07 3.33975 -2.48519e-07L20.6603 1.26569e-06C22.9697 1.46758e-06 24.413 2.5 23.2583 4.5L14.5981 19.5Z"/>
          </svg>
        </div>
        <div
          className={`
          flex
          w-full
          xl:hidden
          transition
          duration-500
          ease-in-out
          transform
          `}
          style={arrowHorizontalPosition}
        >
          <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className="fill-primary" d="M14.5981 19.5C13.4434 21.5 10.5566 21.5 9.40192 19.5L0.74167 4.5C-0.413031 2.5 1.03035 -4.50414e-07 3.33975 -2.48519e-07L20.6603 1.26569e-06C22.9697 1.46758e-06 24.413 2.5 23.2583 4.5L14.5981 19.5Z"/>
          </svg>
        </div>
      </div>
      <div className="relative flex flex-col lg:flex-row shadow-lg bg-white rounded-2xl p-10 lg:pl-0 lg:pb-0 flex-grow gap-4 lg:gap-0 bg-products bg-no-repeat bg-50 lg:bg-auto">
        {/* {autoplay && <Progress active={active} interval={interval}/>} */}
        <div className="flex relative lg:w-2/5 xl:w-1/2 2xl:w-2/5 h-full lg:pr-10 mb-5 lg:mb-0">
          <img className="h-auto w-full rounded-bl-2xl self-end" src={activeProduct.image?.url} />
        </div>
        <div className="lg:w-3/5 xl:w-1/2 2xl:w-3/5 lg:pb-10">
          <h2 className="text-2xl font-medium leading-4 mb-3 underlined">
            {activeProduct.title}
          </h2>
          <div dangerouslySetInnerHTML={createDescription(activeProduct.description)} />
        </div>
      </div>
    </div>
  )
}

export default Slider

Slider.propTypes = {
  active: PropTypes.string.isRequired,
  setActive: PropTypes.func.isRequired,
  products: PropTypes.array.isRequired,
  autoplay: PropTypes.bool.isRequired,
  interval: PropTypes.number.isRequired,
};
