import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import getStaticContent from "/utils/getStaticContent"
import Slider from "src/components/home/Products/Slider"
import { Divider } from "src/components/common"

const Products = ({ dataDescription, dataProducts }) => {
  const createSectionDescription = () => {
    return { __html: `${dataDescription.description}` }
  }

  const options = dataProducts.map((edge, index) => {
    const { name } = { ...edge.node };
    return {id : index, verboseName : name}
  })

  const [activeDemoProduct, setActiveDemoProduct] = useState(0)
  const [active, setActive] = useState(dataProducts[0].node.id)
  const [autoPlay, setAutoPlay] = useState(true)
  const handleActive = slide => {
    setActive(slide)
    setAutoPlay(false)
  }

  const len = dataProducts.length
  const interval = 5000

  useEffect(() => {
    if (autoPlay) {
      var timer = setTimeout(() => {
        if (active <= len - 1) {
          setActive(active + 1)
        } else if (active === len) {
          setActive(1)
        }
      }, interval)
      return () => clearTimeout(timer)
    }
  }, [autoPlay, active, len, interval])

  return (
    <div className="relative bg-bgdark py-28">
      <div className="container">
        <div dangerouslySetInnerHTML={createSectionDescription()} />
        <Slider
          active={active}
          setActive={handleActive}
          products={dataProducts}
          autoplay={autoPlay}
          interval={interval}
        />
        <Divider options={options} active={activeDemoProduct} setActive={setActiveDemoProduct} text={getStaticContent("interested")} buttonText={getStaticContent("demo")} placeholder={getStaticContent("contact-mail")} />
      </div>
    </div>
  )
}

export default Products

Products.propTypes = {
  dataDescription: PropTypes.object.isRequired,
  dataProducts: PropTypes.array.isRequired,
}
