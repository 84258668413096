import React, { useState } from "react";
import PropTypes from 'prop-types';
import { Button } from "src/components/common";

const Capabilities = ({ dataDescription, dataCapabilities }) => {
  const [open, setOpen] = useState();

  const createSectionDescription = () => {
    return { __html: `${dataDescription.description}` }
  }

  const createDescription = (description) => {
    return { __html: `${description}` }
  }

  return (
    <div className="bg-bglight py-28">
      <div className="relative container">
        <div dangerouslySetInnerHTML={createSectionDescription()} />
        <div className="flex flex-col mt-14 space-y-12 relative z-10">
          {dataCapabilities.map((edge, index) => {
            const {
              id,
              title,
              shortDescription,
              longDescription,
              image,
            } = { ...edge.node };
            const even = index % 2 == 0;
            const bg = { backgroundImage: `url(${image.url})` };

            const handleClick = (clicked) => {
              if (open === clicked) {
                setOpen();
                console.log("a")
              } else {
                setOpen(clicked);
                console.log("b")
              }
            }

            return (
              <div key={id} className={`flex ${even ? "" : "lg:justify-end"} w-full`}>
                <div
                  key={id}
                  className={`flex flex-col ${even ? "md:flex-row lg:ml-36" : "md:flex-row-reverse lg:mr-36"} shadow-lg bg-white rounded-2xl transition-all lg:w-3/5 `}
                >
                  <div>
                    <div className="relative h-56 max-h-56 md:w-56 min-w-56 bg-accent-bg md:rounded-2xl">
                      <div className="absolute bg-cover bg-center h-full w-full rounded-t-2xl md:rounded-2xl md:left-3 bottom-3" style={bg} />
                    </div>
                  </div>
                  <div className={`flex-grow p-10 md:p-8 ${even ? "md:pl-12" : "md:pr-12"}`}>
                    <h2 className="text-2xl font-medium leading-8 mb-3 underlined">
                      {title}
                    </h2>
                    <div dangerouslySetInnerHTML={createDescription(shortDescription)} />
                    <div className={`${open !== id && "hidden"} mt-3`} dangerouslySetInnerHTML={createDescription(longDescription)} />
                    <div className={`${!!longDescription ? "block" : "hidden"} mt-5`}>
                      <Button variant="outlined" onClick={() => {handleClick(id)}}>Learn More</Button>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
        <div className="absolute bottom-0 xl:-bottom-60 left-0 right-0 mx-auto z-0 text-center">
          <svg className="inline-block w-full 2xl:w-4/5 h-auto" viewBox="0 0 1344 1349" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className="fill-primary-bg" d="M502.956 367.443C47.079 535.663 182.514 930.97 294.424 1076.21C335.867 1162.43 559.906 1081.64 682.09 1139.91C804.275 1198.19 978.787 873.23 1002.36 841.427C1025.93 809.623 1070.52 453.009 1064.57 323.449C1059.61 215.591 935.529 202.597 829.967 230.352C750.355 251.285 598.173 326.634 502.956 367.443Z" />
          </svg>
        </div>
      </div>
    </div>
  )
}

export default Capabilities;

Capabilities.propTypes = {
  dataDescription: PropTypes.object.isRequired,
  dataCapabilities: PropTypes.array.isRequired,
};
