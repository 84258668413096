import React from "react";
import PropTypes from 'prop-types';

const Selector = ({ children, id, active, setActive }) => {
  return (
    <div
      className={`
      flex-grow
      border-4
      border-transparent
      cursor-pointer
      box-border
      shadow-lg
      bg-white
      rounded-2xl
      text-center
      font-medium
      py-1.5
      px-1
      sm:py-2
      sm:px-3
      lg:py-4
      lg:px-6
      transition
      ${active === id ? "border-primary shadow-none" : "lg:hover:shadow-md lg:hover:bg-bglight lg:hover:border-secondary-bg"}`}
      onClick={() => active !== id && setActive(id)}
      onKeyDown={() => active !== id && setActive(id)}
      role="button"
      tabIndex={id}
    >
      {children}
    </div>
  )
}

export default Selector

Selector.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  active: PropTypes.string.isRequired,
  setActive: PropTypes.func.isRequired,
};
